<template>
  <div>
    <v-tabs v-model="strTab" centered icons-and-text>
      <v-tab href="#tab-nr15">
        Anexo NR 15
        <i :class="$utilidade.getIcone('gerenciar') + ' fa-2x'"></i>
      </v-tab>
      <v-tab href="#tab-nr16">
        Anexo NR 16
        <i :class="$utilidade.getIcone('gerenciar') + ' fa-2x'"></i>
      </v-tab>
      <v-tab href="#tab-cronogramaStatus">
        Cronograma Status
        <i :class="$utilidade.getIcone('gerenciar') + ' fa-2x'"></i>
      </v-tab>
      <v-tab href="#tab-cronogramaPrioridade">
        Cronograma Prioridade
        <i :class="$utilidade.getIcone('gerenciar') + ' fa-2x'"></i>
      </v-tab>
    </v-tabs>

    <ed-form
      :handlerSave="null"
      v-if="!this.$root.$session.loadingContent"
      disableFixedSave
    >
      <parametro
        v-show="strTab == 'tab-nr15'"
        strChave="riscoNr15"
        :key="'tipo-consulta-' + $root.$session.versao + strKey"
        disableIcon
        disableColor
      />
      <parametro
        v-show="strTab == 'tab-nr16'"
        strChave="riscoNr16"
        :key="'status-' + $root.$session.versao + strKey"
        disableIcon
        disableColor
      />

      <parametro
        v-show="strTab == 'tab-cronogramaStatus'"
        strChave="cronogramaStatus"
        :key="'cronogramaStatus-' + $root.$session.versao + strKey"
        disableIcon
        disableColor
      />

      <parametro
        v-show="strTab == 'tab-cronogramaPrioridade'"
        strChave="cronogramaPrioridade"
        :key="'cronogramaPrioridade-' + $root.$session.versao + strKey"
        disableIcon
        disableColor
      />
    </ed-form>
  </div>
</template>

<script>
import Parametro from "@/components/common/parametro/editar";
import CadastroGeral from "./partials/geral.vue";

import { EdForm } from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {},
  components: {
    Parametro,
    CadastroGeral,
    EdForm,
  },
  provide() {
    return {
      bind: this.bind,
    };
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      bind: {},
      strTab: "tab-nr15",
      strKey: this.$utilidade.randomString(),
      formData: {},
    };
  },
  watch: {},
  computed: {},
  methods: {
    initialize() {
      // this.$root.$dialog.loading(true);
      // Promise.all([
      //   this.$root.$request.get("Common/Parametro", {
      //     strChave: "agendamentoConfiguracao",
      //     boolBuscarSomenteAtivo: false,
      //   }),
      // ]).then(([objConfiguracao]) => {
      //   this.$root.$dialog.loading(false);
      //   if (objConfiguracao.status == 200 && objConfiguracao.result) {
      //     if (!objConfiguracao.result.strValor) {
      //       objConfiguracao.result.strValor = {};
      //     }
      //     this.formData = {
      //       agendamentoConfiguracao: Object.assign(objConfiguracao.result, {}),
      //     };
      //   }
      //   this.bind.boolCarregado = true;
      // });
    },

    salvar() {
      this.$root.$dialog.loading(true);

      this.$root.$request.post("Common/Parametro", this.formData).then((objResponse) => {
        this.$root.$dialog.loading(false);

        if (objResponse.status == 200) {
          this.strKey = this.$utilidade.randomString();
          this.initialize();
        }
      });
    },
  },
};
</script>
